<template>
  <v-container
    fluid
  >
    <v-overlay
      v-if="loading"
      absolute
      :value="loading"
      opacity="1"
      color="white"
      class="full-screen"
      text-xs-center
    >
      <div class="d-flex flex-column justify-center align-center">
        <v-progress-circular
          :size="50"
          color="primary"
          indeterminate
        />
        <img
          src="@/assets/Lavirra_logo_SVG.svg"
          height="28px"
          width="auto"
          class="mt-6"
        >
        <span
          class="mt-4 ml-2"
          :style="`font-size: 15px; color: ${$vuetify.theme.themes.light.primary};`"
        >
          {{ message }}
        </span>
      </div>
    </v-overlay>
    <v-card
      :style="`height: ${($vuetify.breakpoint.height - 72).toString() + 'px'}; overflow-x: hidden;`"
    >
      <v-toolbar
        flat
        width="100%"
        :rounded="false"
        class="form-toolbar"
      >
        <v-toolbar-title class="mt-4">
          {{ $t('members|delete_user_seats') }}
        </v-toolbar-title>
      </v-toolbar>
      <v-row class="fill-height">
        <v-col
          cols="4"
        >
          <v-card
            elevation="8"
            class="mt-5 ml-5 sticky-card"
            max-width="600"
            :style="`height: ${($vuetify.breakpoint.height - 172).toString() + 'px'}; overflow-x: auto;`"
          >
            <v-card-title
              class="justify-center"
            >
              <h3 style="color: #234e79;">
                {{ $t('common|law_firm') }}/{{ $t('company|company') }}
              </h3>
            </v-card-title>
            <v-card-text
              class="text-center"
            >
              <div>
                <h4>{{ $t('members|current_subscription') }}:</h4>
                <span>{{ $t('members|actual_seats') }}: {{ company.seats }}</span><br>
              </div>
              <div>
                <span>{{ $t('members|seats_to_be_deleted') }}: {{ seatsToBeDeleted }}</span>
              </div>
              <div
                class="mt-8"
                style="font-weight: bold;"
              >
                {{ $t('common|total') }}: {{ getTotal() }}
              </div>
              <span>{{ $t('members|billed_montly') }}</span>
            </v-card-text>
            <v-divider class="px-2 divider-position-company" />
            <div class="element-position ml-4">
              <v-icon>mdi-check</v-icon>
              <a
                class="ml-2"
                href="https://lavvira.net"
                target="_blank"
              >
                {{ $t('expressions|all_core_features') }}
              </a><br>
              <v-icon>mdi-check</v-icon>
              <a
                class="ml-2"
                href="https://lavvira.net"
                target="_blank"
              >
                {{ $t('expressions|all_advanced_features') }}
              </a><br>
              <v-icon>mdi-check</v-icon>
              <span class="ml-2">{{ cfg.storageData.companyStorage }} {{ $t('common|storage') }}</span><br>
              <v-icon>mdi-check</v-icon>
              <span class="ml-2">{{ $t('common|premium_support') }}</span>
            </div>
          </v-card>
        </v-col>
        <v-col cols="8">
          <v-form
            ref="formRef"
          >
            <v-card-text>
              <v-row
                class="no-gutters"
              >
                <v-text-field
                  v-model="seatsToBeDeleted"
                  :label="$t('members|seats_to_be_deleted')"
                  type="number"
                  min="0"
                  :max="company.seats - company.members.length"
                  required
                  outlined
                  dense
                  class="mt-2 mx-3"
                />

                <v-col cols="12">
                  <v-card-title>
                    {{ $t('members|members_overview') }}
                  </v-card-title>
                </v-col>
              </v-row>
              <v-row
                v-for="(member, index) in company.members"
                :key="`member-${index}`"
                class="no-gutters"
              >
                <v-col cols="9">
                  <v-text-field
                    v-model="member.member_id.email"
                    outlined
                    dense
                    :label="$t('user|email')"
                    persistent-hint
                    required
                    clearable
                    disabled
                    class="mx-3 my-2"
                  />
                </v-col>
                <v-col cols="3">
                  <v-text-field
                    v-model="member.member_id.currentUserRole"
                    outlined
                    dense
                    :label="$t('members|user_role')"
                    persistent-hint
                    required
                    clearable
                    disabled
                    class="mx-3 my-2"
                  />
                </v-col>
              </v-row>
              <v-row
                v-for="(seat, index) in company.seats - Number(seatsToBeDeleted) - company.members.length"
                :key="`seat-${index}`"
                class="no-gutters"
              >
                <v-col cols="9">
                  <v-text-field
                    outlined
                    dense
                    :label="$t('members|free_seat')"
                    persistent-hint
                    required
                    clearable
                    disabled
                    class="mx-3 my-2"
                  />
                </v-col>
                <v-col cols="3">
                  <v-text-field
                    outlined
                    dense
                    :label="$t('common|unknown')"
                    persistent-hint
                    required
                    clearable
                    disabled
                    class="mx-3 my-2"
                  />
                </v-col>
              </v-row>
            </v-card-text>
          </v-form>
          <v-card-actions>
            <v-spacer />
            <v-btn
              color="primary"
              rounded
              small
              :disabled="Number(seatsToBeDeleted) === 0"
              min-width="150"
              class="mt-2"
              :loading="loading"
              @click="paymentProcessing"
            >
              {{ $t('payments|continue_to_checkout') }}
            </v-btn>
          </v-card-actions>
        </v-col>
      </v-row>
    </v-card>
    <template>
      <StripeCheckout
        ref="checkoutRef"
        :pk="publishableKey"
        :session-id="sesId"
        @loading="v => loading = v"
      />
    </template>
  </v-container>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { StripeCheckout } from '@vue-stripe/vue-stripe'
import cfg from '@/config'

export default {
  components: {
    StripeCheckout
  },
  data () {
    return {
      publishableKey: process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY,
      sesId: null,
      loading: false,
      message: '',
      seatsToBeDeleted: 0,
      cfg
    }
  },
  computed: {
    ...mapState({
      account: state => state.account.account,
      company: state => state.company.company
    })
  },
  methods: {
    ...mapActions({
      updateCompanyMemberSeats: 'company/updateCompanyMemberSeats',
      addToast: 'toasts/addToast'
    }),
    getTotal () {
      const allSeats = this.company.seats - Number(this.seatsToBeDeleted)
      let actualPreice = Number(cfg.preices.companyAccountPreis)
      if (this.company && this.company.subscriptionStatus === 'beta') return '0' + ' ' + '€'
      else {
        return (allSeats * actualPreice).toString() + ' ' + '€'
      }
    },
    async paymentProcessing () {
      this.loading = true
      const _id = this.company._id
      const payload = {
        seatsToBeDeleted: this.seatsToBeDeleted,
        newInvitedMembers: [],
        action: 'deleteSeats'
      }
      try {
        await this.updateCompanyMemberSeats({ _id, payload })
        this.addToast({
          title: this.$t('expressions|successfully_changed_seats_number_in_company'),
          color: 'white',
          snackbarColor: 'success'
        })
        this.loading = false
        this.$router.push('/members')
      } catch (error) {
        console.error(error)
        this.addToast({
          title: this.$t('error|something_went_wrong'),
          color: 'white',
          snackbarColor: 'error'
        })
        this.loading = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.form {
  position: relative;
  display: flex;
  flex-direction: column;
}
.form-toolbar {
  position: sticky;
  top: 0;
  left: 0;
  z-index: 2;
}
.sticky-card {
  position: sticky;
  top: 85px;
  left: 0;
  z-index: 1;
}
.element-position {
  position: absolute;
  width: 90%;
  bottom: 30px;
}
.divider-position-company {
  position: absolute;
  width: 100%;
  bottom: 160px;
}
.full-screen {
    height: 100%;
    width: 100%;
  }
</style>
